import React, { ReactNode, Fragment } from 'react';
import { RouteComponentProps } from '@reach/router';

import ScholarshipFinderContextProvider from './scholarship-finder-context';

type ScholarshipFinderProps = RouteComponentProps & {
  children?: ReactNode;
};
const ScholarshipsFinder = (props: ScholarshipFinderProps) => (
  <Fragment>
    <ScholarshipFinderContextProvider>{props.children}</ScholarshipFinderContextProvider>
  </Fragment>
);

export default ScholarshipsFinder;

import styled from '@emotion/styled';
import { Col, Row, Divider, Breadcrumb } from 'antd';
import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { get } from 'lodash';
import NavigateNext from '@material-ui/icons/NavigateNext';

import { ScholarshipFinderModal } from './scholarship-finder-modal';
import { AmountAward } from './schema';
import { useScholarshipFinderQuery } from './scholarship-finder-context';
import ScholarshipsFilter from './scholarships-filter';

import { TitleRow, TitleCol, StyledMessage, StyledSpin, StyledContainer } from '../common/styles';
import { generateBreadcrumb } from '../common/utils';
import { ScholarshipList } from '../common/scholarship-list';
import { FormElementChangeEvent, InputWithWrapper, Select } from '../../../../components/forms';
import { DefaultSearchIcon } from '../../../../components/admin/common/common-styled-component';
import theme from '../../../../theme';
import { NavigationWrapper } from '../../../../components/common/page-header';
import { Pagination, DEFAULT_PAGE_SIZE, PageChangeEvent } from '../../../../components/table/pagination';

const NO_SEARCH_RESULTS_MESSAGE = `Your search returned no results, please clear your filters and broaden your criteria. Only scholarships matching all criteria in your search will be returned.`;

const Caption = styled.div`
  color: #000;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const ModalButtonContainer = styled.div`
  margin-top: 10px;
  @media (min-width: ${theme.screenSm}) {
    margin-top: 50px;
  }
`;

const Container = styled(Row)`
  min-height: 250px;
  margin-bottom: 20px;
  @media (min-width: ${theme.screenSm}) {
    min-height: 110px;
  }
  @media (min-width: ${theme.screenLg}) {
    min-height: 50px;
  }
`;

export const StyledPagination = styled.div`
  margin-top: 20px;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 0;
`;

const MULTIPLE_SELECT_PLACEHOLDER = 'Select one or more';

const ScholarshipFinderList = (_: RouteComponentProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [scholarshipValue, setScholarshipValue] = useState([]);
  const { data, queryOptions, setFilterQuery, loading, scholarshipsError } = useScholarshipFinderQuery();

  const scholarships = data?.scholarships || [];
  const total = get(data, 'totalCount', 0);
  const pageNumber = get(queryOptions, 'offset', 0) / DEFAULT_PAGE_SIZE + 1;

  const onSelectfilterAmountAwardState = (e: FormElementChangeEvent) => {
    setFilterQuery({ amount_of_award: e.value }); // eslint-disable-line
  };

  const onPressEnter = (e: any) => {
    setFilterQuery({ searchText: e.target.value });
  };

  const handleSearchBlur = (e: FormElementChangeEvent) => {
    setFilterQuery({ searchText: e.value });
  };

  const onPageChange = (event: PageChangeEvent) => {
    setFilterQuery({ offset: event.offset });
  };

  const getRedirectLink = (id?: string) => {
    return `/scholarship-finder/${id}`;
  };

  const clearAllFilters = () => {
    const clearedModalFilters = Object.keys(queryOptions).reduce(
      (result, key) => ({ ...result, [key]: undefined }),
      {}
    );
    setSearchValue('');
    setScholarshipValue([]);
    setFilterQuery({
      ...clearedModalFilters,
    });
  };

  const onchangeSearch = (e: any) => {
    setSearchValue(e.value);
  };

  const renderHeaderToFilterSearch = () => {
    const { amount_of_award: awardRange } = queryOptions;
    return (
      <Container justify="space-between">
        <Col xs={24} sm={11} md={11} lg={7}>
          <Caption>Search</Caption>
          <InputWithWrapper
            value={searchValue}
            onChange={onchangeSearch}
            prefix={<DefaultSearchIcon />}
            placeholder="Search by name"
            onPressEnter={onPressEnter}
            onBlur={handleSearchBlur}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={9}>
          <Caption>Filter by Scholarship Name</Caption>
          <ScholarshipsFilter scholarshipValue={scholarshipValue} />
        </Col>
        <Col xs={24} sm={11} md={11} lg={6}>
          <Caption>Filter by Award Range</Caption>
          <Select
            mode="multiple"
            value={awardRange}
            placeholder={MULTIPLE_SELECT_PLACEHOLDER}
            optionFilterProp="children"
            onChange={onSelectfilterAmountAwardState}
            filterOption={(input: any, option: any) =>
              option &&
              option.props !== null &&
              option.props.children !== null &&
              typeof option.props.children === 'string' &&
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={AmountAward}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={1}>
          <ModalButtonContainer>
            <ScholarshipFinderModal clearAllFilters={clearAllFilters} />
          </ModalButtonContainer>
        </Col>
      </Container>
    );
  };

  const renderPagination = () => {
    return (
      <StyledPagination>
        <Pagination current={pageNumber} total={total} onChange={onPageChange} />
      </StyledPagination>
    );
  };

  return (
    <>
      <StyledContainer>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb({ Home: '/' }, { 'Resources: Scholarship Finder': '' }).map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        <TitleRow>
          <TitleCol>Scholarship Finder</TitleCol>
        </TitleRow>
        <Row justify="center" gutter={32}>
          <Col xs={20} lg={24}>
            <div style={{ marginBottom: '20px' }}>
              {renderHeaderToFilterSearch()}
              {!loading && !data?.totalCount && <StyledMessage>{NO_SEARCH_RESULTS_MESSAGE}</StyledMessage>}
              {renderPagination()}
              <StyledDivider />
              <StyledSpin spinning={loading}>
                {scholarshipsError && <StyledMessage>{scholarshipsError.message}</StyledMessage>}
                {!scholarshipsError && <ScholarshipList dataSource={scholarships} getRedirectLink={getRedirectLink} />}
              </StyledSpin>
              {total ? <StyledDivider /> : null}
              {renderPagination()}
            </div>
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

export default ScholarshipFinderList;

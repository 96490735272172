import { ScholarshipCMSType } from '../../../../types/models/scholarship-finder';
import { OptionProps } from '../../../../components/forms/select';

const States = [
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'District of Columbia', value: 'District of Columbia' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Montana', value: 'Montana' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New York', value: 'New York' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'US Virgin Islands', value: 'US Virgin Islands' },
  { label: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wyoming', value: 'Wyoming' },
];
export interface ISectionItem {
  label: string;
  name: keyof ScholarshipCMSType;
  options: OptionProps[];
  tooltip?: string;
}

const Gender = [
  { label: 'All', value: 'All' },
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
];

const Race = [
  { label: 'All', value: 'All' },
  { label: 'White', value: 'White' },
  { label: 'Black or African American', value: 'Black or African American' },
  { label: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native' },
  { label: 'Asian', value: 'Asian' },
  { label: 'Native Hawaiian or Pacific Islander', value: 'Native Hawaiian or Pacific Islander' },
];

const Hispanic = [
  { label: 'Yes', value: 'Yes,All' },
  { label: 'No', value: 'No,All' },
];

const ResidentState = [
  { label: 'Clear Filter', value: undefined },
  { label: 'Nationwide', value: 'Nationwide' },
  ...States,
];

const UndergraduateLevel = [
  { label: 'Clear Filter', value: undefined },
  { label: 'All', value: 'All' },
  { label: 'High School Senior', value: 'High School Senior' },
  { label: 'Undergraduate - all', value: 'Undergraduate-All' },
  { label: 'Freshman', value: 'Freshman' },
  { label: 'Sophmore', value: 'Sophmore' },
  { label: 'Junior', value: 'Junior' },
  { label: 'Senior', value: 'Senior' },
];

const GraduateProgramType = [
  { label: 'All', value: 'All' },
  { label: 'Medical School', value: 'Medical School' },
  { label: 'Law School', value: 'Law School' },
  { label: 'Business School', value: 'Business School' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Computer Science', value: 'Computer Science' },
  { label: 'Other', value: 'Other' },
];

const FieldStudyCategory = [
  { label: 'Clear Filter', value: undefined },
  { label: 'All', value: 'All' },
  { label: 'Sciences', value: 'Sciences' },
  { label: 'Humanites', value: 'Humanites' },
  { label: 'Social Sciences', value: 'Social Sciences' },
  { label: 'Law', value: 'Law' },
  { label: 'Business', value: 'Business' },
  { label: 'Medicine', value: 'Medicine' },
  { label: 'Technology/Computer Science', value: 'Technology/Computer Science' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Mathematics', value: 'Mathematics' },
  { label: 'Education', value: 'Education' },
];

const HouseholdIncome = [
  { label: 'Clear Filter', value: undefined },
  { label: 'All', value: 'All' },
  { label: 'Less than $24,600', value: 'Less than 24600' },
  { label: '$24,601 - $50,000', value: '24601-50000' },
  { label: '$50,001 - $135,600', value: '50001-135600' },
  { label: '$135,601 or more', value: '135601' },
];

const Citizenship = [
  { label: 'Clear Filter', value: undefined },
  { label: 'All', value: 'All' },
  { label: 'US Citizen', value: 'US Citizen' },
  { label: 'Deferred Action for Childhood Arrivals (DACA)', value: 'DACA Status' },
  { label: 'Undocumented', value: 'Undocumented' },
  { label: 'Permanent Resident', value: 'Permanent Resident' },
  { label: 'Other', value: 'Other' },
];

const StudyAbroadScholarshipsFellowships = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const AmountAward = [
  { value: 'Last-dollar (full-ride) Scholarships', label: 'Last-dollar (full-ride) Scholarships' },
  { value: '100-1000', label: '$100-$1,000' },
  { value: '1001-5000', label: '$1,001-$5,000' },
  { value: '5001-20000', label: '$5,001-$20,000' },
  { value: '20001-100000', label: '$20,001-$100,000' },
];

export const formSchema: ISectionItem[] = [
  {
    label: 'Gender',
    name: 'gender',
    options: Gender,
  },
  {
    label: 'Race',
    name: 'race',
    options: Race,
  },
  {
    label: 'Hispanic (y/n)',
    name: 'hispanic',
    options: Hispanic,
  },
  {
    label: 'Students Resident State (Geographic Eligibility)',
    name: 'geographic_eligibility',
    options: ResidentState,
  },
  {
    label: 'Undergraduate Level',
    name: 'undergraduate_level',
    options: UndergraduateLevel,
  },
  {
    label: 'Graduate Program Type',
    name: 'graduate_level',
    options: GraduateProgramType,
  },
  {
    label: 'Field of Study Category',
    name: 'field_of_study',
    options: FieldStudyCategory,
  },
  {
    label: 'Household Income',
    name: 'family_income',
    options: HouseholdIncome,
  },
  {
    label: 'Citizenship',
    name: 'citizenship',
    options: Citizenship,
  },
  {
    label: 'Study Abroad Scholarships/Fellowships',
    name: 'study_abroad_scholarships_fellowships',
    options: StudyAbroadScholarshipsFellowships,
  },
];

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import produce from 'immer';

import { useScholarshipFinderQuery } from './scholarship-finder-context';

import { Scholarship as ScholarshipModel } from '../../../../types/models/scholarship';
import { FormElementChangeEvent, Select } from '../../../../components/forms';
import { useQuery } from '../../../../hooks/use-query';
import { getScholarshipFinderFilters } from '../../../../apis/public/scholarship-finder';

const StyledSelect = styled(Select)`
  box-shadow: none !important;
  border-radius: 4px !important;
  line-height: 1.5 !important;
  border: 1px solid #aaa;

  &&::placeholder {
    color: #aaa;
  }
  
  && .ant-select-selector {
    height: 42px !important;
    border: none !important;
  }

  && .ant-select-selection-placeholder {
    line-height: 42px  !important;
  }

  && input {
    height: 42px !important;
  }
`;

function useDebouncedState() {
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchTermChange = debounce((value: string) => {
    if (value.length > 3 || value.length === 0) {
      setSearchTerm(value);
    }
  }, 300);

  return {
    onSearchTermChange,
    searchTerm,
  };
}

type Scholarship = Pick<ScholarshipModel, 'id' | 'name'>;
type ScholarshipMap = {
  [key: string]: Scholarship;
};

const ScholarshipsFilter = ({ scholarshipValue }: { scholarshipValue: any }) => {
  const { setFilterQuery } = useScholarshipFinderQuery();
  const { onSearchTermChange: onSearch, searchTerm } = useDebouncedState();
  const [filterPage, setFilterPage] = useState<{ [key: string]: number }>({});
  const [scholarships, setScholarships] = useState<Scholarship[]>([]);
  const [selectValue, setSelectValue] = useState<string[]>([]);

  useEffect(() => {
    if (!scholarshipValue.length) {
      setSelectValue([]);
      setFilterQuery({ id: [] });
    }
    // CAUTION. Adding `setFilterQuery` as a dependency to this hook will run infinite loop
  }, [scholarshipValue]); // eslint-disable-line

  const { isLoading } = useQuery(
    [getScholarshipFinderFilters.QUERY_KEY, { searchTerm, page: filterPage[searchTerm] || 0 }],
    ({ queryKey }: any) => getScholarshipFinderFilters(queryKey[1]),
    {
      onSuccess: (data) => {
        setScholarships((previous) => {
          const previousUMap = previous.reduce(
            (result: ScholarshipMap, scholarship) =>
              produce(result, (draft) => {
                draft[scholarship.id] = scholarship;
              }),
            {}
          );

          const currentDataUMap = data.reduce(
            (result: ScholarshipMap, scholarship) =>
              produce(result, (draft) => {
                draft[scholarship.id] = scholarship;
              }),
            {}
          );

          return Object.values(
            produce(previousUMap, (draft) => {
              Object.assign(draft, currentDataUMap);
            })
          ).sort((a: Scholarship, b: Scholarship) => {
            if (!a || !b) {
              return 0;
            }
            if (a && b && a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        });
      },
    }
  );

  const _nextFilterPage = useCallback(() => {
    setFilterPage((previous) =>
      produce(previous, (draft) => {
        if (searchTerm in draft) {
          draft[searchTerm] += 1;
        } else {
          draft[searchTerm] = 1;
        }
      })
    );
  }, [searchTerm]);

  const handleSelectChange = (e: FormElementChangeEvent) => {
    setSelectValue(e.value);
    setFilterQuery({ id: e.value });
  };

  const options = useMemo(() => {
    return (scholarships || []).map((option) => ({ label: option.name, value: option.id, children: [] }));
  }, [scholarships]);

  const onScroll = useCallback(
    (e) => {
      const target = e.target;
      if (!isLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
        _nextFilterPage();
      }
    },
    [isLoading, _nextFilterPage]
  );

  return (
    <StyledSelect
      placeholder="Select one or more"
      mode="multiple"
      showSearch
      value={selectValue}
      optionFilterProp="children"
      filterOption={(input: any, option: any) =>
        option &&
        option.props !== null &&
        option.props.children !== null &&
        typeof option.props.children === 'string' &&
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={options}
      loading={isLoading}
      autoClearSearchValue={false}
      onChange={handleSelectChange}
      onSearch={onSearch}
      onPopupScroll={onScroll}
    />
  );
};

export default ScholarshipsFilter;

import React from 'react';
import { Router } from '@reach/router';

import ScholarshipFinderDetail from '../containers/myhsf/scholarship/scholarship-finder/scholarship-finder-detail';
import ScholarshipsFinder from '../containers/myhsf/scholarship/scholarship-finder';
import ScholarshipFinderList from '../containers/myhsf/scholarship/scholarship-finder/scholarship-list-finder';
import PublicLayout from '../layout/public-layout';

export default function ScholarshipFinder() {
  return (
    <PublicLayout seoTitle="Scholarship Finder">
      <Router>
        <ScholarshipsFinder path="/scholarship-finder">
          <ScholarshipFinderList path="/" />
          <ScholarshipFinderDetail path="/:id" />
        </ScholarshipsFinder>
      </Router>
    </PublicLayout>
  );
}

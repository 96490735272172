import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import { Spin } from 'antd5';

import theme from '../../../../theme';

export const TitleRow = styled(Row)`
  flex-direction: column;
  margin-bottom: 20px;
`;

export const TitleCol = styled(Col)`
  color: ${theme.colorsBlack};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
`;

export const StyledUrl = styled.a`
  color: ${theme.colorsSecondaryBlue};
  font-size: 18px;
  line-height: 28px;
  text-decoration: underline;
`;

export const StyledContainer = styled.div`
  max-width: ${theme.screenXl};
  margin: 0 auto;
  min-height: 33vh;

  padding: 0 25px;

  @media (min-width: ${theme.screenLg}) {
    padding: 0;
  }
`;

export const StyledMessage = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  font-size: 18px;
  margin: 20px 20px;
  text-align: center;
  word-break: break-word;
`;

export const StyledSpin = styled(Spin)`
  width: 100%;
`;
